import Layout from "./components/Layout";
import "./style.css";

function App() {
	return (
		<div
			style={{
				display: "flex",
				width: "100vw",
				height: "100vh",
				alignItems: "center",
				justifyContent: "center",
				backgroundColor: "lightgrey",
			}}
		>
			{/* <Layout /> */}
			<div>
				<img src="/thankyou.jpg" alt="thankyou-message" className="thankyou-image" />
			</div>
		</div>
	);
}

export default App;
